/* import __COLOCATED_TEMPLATE__ from './upgrade-modal.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class UpgradeModal extends Component {
  @service appService;

  get app() {
    return this.appService.app;
  }
}
