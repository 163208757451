/* import __COLOCATED_TEMPLATE__ from './self-serve.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { taskFor } from 'ember-concurrency-ts';

import { type TaskGenerator } from 'ember-concurrency';
import type Plan from 'embercom/models/plan';
import type QuoteService from 'embercom/services/quote-service';
import type { Metric } from 'embercom/models/data/pricing/metric-types';
import type IntlService from 'ember-intl/services/intl';

interface Signature {
  isDowngrade: boolean;
  showUpgradePathPlans: boolean;
  featureLessHeaders: boolean;
  updateSubscriptionTask: () => TaskGenerator<void>;
  plan: Plan;
}

export default class SelfServe extends Component<Signature> {
  @service declare quoteService: QuoteService;
  @service declare intl: IntlService;

  get seatPrice() {
    let seatPrice = this.quoteService
      .getQuoteById([this.args.plan.idAsNumber])
      ?.fullSeatPrice(this.args.plan.idAsNumber, this.args.plan.product.pricingMetric as Metric);
    return seatPrice ? this.quoteService.formatPrice(seatPrice) : undefined;
  }

  get buttonText() {
    return this.disableUpdateSubscription
      ? this.intl.t('paywalls.upgrade-modal.sidebar.self-serve.copilot.monthly.button.processing')
      : this.intl.t('paywalls.upgrade-modal.sidebar.self-serve.copilot.monthly.button.continue');
  }

  get disableUpdateSubscription() {
    return taskFor(this.args.updateSubscriptionTask).isRunning;
  }

  @action
  updateSubscription() {
    taskFor(this.args.updateSubscriptionTask).perform();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Paywalls::UpgradeModal::Sidebar::SelfServe': typeof SelfServe;
  }
}
