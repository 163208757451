/* import __COLOCATED_TEMPLATE__ from './manage-subscription.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Plan from 'embercom/models/plan';
import { PLAN_DATA, FIN_AI_COPILOT_BASE_ID } from 'embercom/lib/billing';

import type RouterService from '@ember/routing/router-service';
import type QuoteService from 'embercom/services/quote-service';
import type IntlService from 'ember-intl/services/intl';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

interface Signature {
  closeUpgradeModal: () => void;
  plan: Plan;
  showUpgradePathPlans: boolean;
  featureLessHeaders: boolean;
  featureName: string;
  openedFromTeammatesArea: boolean;
  isDowngrade: boolean;
  updateSubscriptionTask: () => TaskGenerator<void>;
  selectedPlanName?: string;
}

export default class ManageSubscriptionComponent extends Component<Signature> {
  @service purchaseAnalyticsService: any;
  @service declare router: RouterService;
  @service declare intl: IntlService;
  @service declare quoteService: QuoteService;
  @service declare appService: any;

  @action
  redirectToManageSubscription() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'manage_subscription_button',
      place: 'paywall_sidebar',
      context: 'upgrade_annual_plan',
      planId: this.args.plan?.idAsNumber,
    });

    let queryParams = {
      planid: '',
      featurename: '',
      section: '',
      highlight: '',
    };

    if (this.args.showUpgradePathPlans || this.args.featureLessHeaders || this.args.isDowngrade) {
      queryParams.section = 'plans';
      queryParams.highlight = 'subscription-plans';
    }
    if (this.args.featureName && this.args.plan.idAsNumber) {
      if (this.args.plan.product.addon) {
        queryParams.section = 'addons';
        queryParams.highlight = 'subscription-addons';
      } else {
        queryParams.section = 'plans';
        queryParams.highlight = 'subscription-plans';
      }
      queryParams.featurename = this.args.featureName;
      queryParams.planid = `${this.args.plan.idAsNumber}`;
    }
    if (this.args.openedFromTeammatesArea) {
      queryParams.section = 'seats';
      queryParams.highlight = 'subscription-seats';
    }

    if (this.isCopilot) {
      queryParams = {
        // we don't pass a planid as otherwise, the manage subscription page will display the subscription as updated
        planid: '',
        featurename: '',
        section: 'seats',
        highlight: 'subscription-seats',
      };
    }

    this.args.closeUpgradeModal();
    this.router.transitionTo(
      'apps.app.settings.subscription.billing.manage-subscription',
      this.appService.app.id,
      {
        queryParams,
      },
    );
  }

  get buttonTextForCopilot() {
    return this.disableUpdateSubscription
      ? this.intl.t('paywalls.upgrade-modal.sidebar.self-serve.copilot.monthly.button.processing')
      : this.intl.t('paywalls.upgrade-modal.sidebar.self-serve.copilot.monthly.button.continue');
  }

  get disableUpdateSubscription() {
    return taskFor(this.args.updateSubscriptionTask).isRunning;
  }

  @action
  updateSubscription() {
    taskFor(this.args.updateSubscriptionTask).perform();
  }

  get planData() {
    return PLAN_DATA[this.args.plan?.id];
  }

  get isCopilot() {
    return this.args.plan?.id === FIN_AI_COPILOT_BASE_ID;
  }

  get quote() {
    return this.quoteService.getQuoteById([this.args.plan?.idAsNumber]);
  }

  get fullSeatPrice() {
    return (
      this.quote?.fullSeatPrice(this.args.plan?.idAsNumber, this.args.plan.product.pricingMetric) ||
      0
    );
  }

  get formattedFullSeatPrice() {
    return this.quoteService.formatPrice(this.fullSeatPrice);
  }

  get formattedDiscountedSeatPrice() {
    return this.quoteService.formatPrice(this.discountedSeatPrice);
  }

  get discountedSeatPrice() {
    return (
      this.quote?.discountedAnnualSeatPrice(
        this.args.plan?.idAsNumber,
        this.args.plan.product.pricingMetric,
      ) || 0
    );
  }

  get selfServeAnnualHeader() {
    if (this.planData?.manageSubscriptionSidebarHeader) {
      return this.intl.t(this.planData?.manageSubscriptionSidebarHeader);
    }
    return undefined;
  }

  get descriptionItems() {
    return this.planData?.manageSubscriptionSidebarDescriptionItems || [];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Paywalls::UpgradeModal::Sidebar::ManageSubscription': typeof ManageSubscriptionComponent;
  }
}
