/* import __COLOCATED_TEMPLATE__ from './subscription-upgrade.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/use-brace-expansion */
import { action, computed } from '@ember/object';
import { alias, and, not, readOnly, reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import ConfirmComponent from 'embercom/components/billing/confirm-component';
import { CORE_GROW_ID, CORE_SEAT_METRIC, SMS_BASE_ID, PLAN_DATA } from 'embercom/lib/billing';
import {
  sendIntercomUpdate,
  showNewMessageInIntercomWidget,
} from 'embercom/lib/intercom-widget-helper';
import numericFormatter from 'embercom/lib/numeric-formatter';
import usageFormatter from 'embercom/lib/usage-formatter';
import { isEmpty } from 'underscore';

export default ConfirmComponent.extend({
  customerService: service(),
  quoteService: service(),
  selfServeTrialsService: service(),
  router: service(),
  paywallService: service(),
  intl: service(),
  customer: readOnly('customerService.customer'),
  product: alias('plan.product'),
  productName: readOnly('product.name'),
  app: alias('appService.app'),
  metricNumber: computed('product', 'customerService.prices', function () {
    if (!this.product || !this.customerService.prices) {
      return '';
    }
    let metric = this.get('product.pricingMetric');
    if (metric && metric !== 'fixed') {
      let price = this.get('customerService.prices').find((price) =>
        price.hasSamePlans([this.get('plan.idAsNumber')]),
      );
      return price && price.get(metric);
    }
  }),

  allowTrials: reads('args.allowTrials'),
  onAnnualPricing5: reads('args.onAnnualPricing5'),
  liteSeatCount: readOnly('app.humanAdminsWithLiteSeat.length'),
  teammateUrl: computed('app.id', function () {
    return `/a/apps/${this.app.id}/settings/teammates`;
  }),
  showLiteSeatsWarning: computed(
    'product.downgrading',
    'customerService',
    'liteSeatCount',
    'plan',
    function () {
      return (
        this.customerService.onPricing5_X &&
        this.product.downgrading &&
        PLAN_DATA[this.plan.id].liteSeatLimit === 0 &&
        this.liteSeatCount > 0
      );
    },
  ),

  showLinkToBillingSummary: computed('app', 'router', 'customerService', 'plan', function () {
    return (
      this.app.onPricing5 &&
      !this.router.currentRouteName?.includes('billing') &&
      this.customerService.isPricing5CorePlan(this.plan.id)
    );
  }),
  /**
   * If a plan requires multiple plans text is shown to say that you need x plans...
   * However, if a plan is upgrading there is conflicting text which is why we...
   * Don't show this if any plan inside the priceRows is an upgrade
   */
  shouldDisplayAddOnEligiblityText: computed('priceRows', function () {
    return !this.priceRows.some((priceRow) => priceRow.isUpgradingTier);
  }),

  // For each of the planModel.product.plans
  // Check if inCurrentSubscription and has a tierId lower than the current plan model
  // If yes then only pick the first one, this is the tier we're upgrading from.
  isPlanUpgradingTier(planModel) {
    // If the plan is currently in the subscription it should mean
    // that the plan isn't a new plan that is being upgraded
    if (planModel.inCurrentSubscription) {
      return {
        upgradeFromPlan: undefined,
        isUpgradingTier: false,
      };
    }

    let currentTierId = planModel.tierId;
    let siblingPlans = planModel.product.plans;
    let upgradingFromPlan = siblingPlans.find((siblingPlan) => {
      return siblingPlan.tierId < currentTierId && siblingPlan.inCurrentSubscription;
    });

    return {
      upgradeFromPlan: upgradingFromPlan && this.getPlanDetails(upgradingFromPlan?.idAsNumber),
      isUpgradingTier: isPresent(upgradingFromPlan),
    };
  },

  // Expects planId to be a number
  getPlanDetails(planId) {
    let planModel = this.customerService.plans.findBy('idAsNumber', planId);
    let priceModel = this.customerService.getPriceFromPlanIds([planId]);
    let originalActivePlans = this.customerService.originalActivePlans.mapBy('idAsNumber'); // before we added anything

    /**
     * This won't work if there are more than 2 additional plans being added, reason being
     * is because we compare the original plans vs all the new additive plans
     *
     * When it really should be original plan vs this current plan
     */
    let newPrice = this.prices.find((price) =>
      price.hasSamePlans(this.customerService.activePlans.mapBy('idAsNumber')),
    );

    let originalPrice = this.prices.find((price) => price.hasSamePlans(originalActivePlans));

    /**
     * To calculate the price difference we:
     * take original active plans total price -
     */
    let priceDifferential = this.calculatePriceDifferential(newPrice, originalPrice);

    let { isUpgradingTier, upgradeFromPlan } = this.isPlanUpgradingTier(planModel);
    return {
      price: priceModel,
      hideBreakdown: !priceModel || planModel.product.removing,
      formattedPrice: this.formatPrice(priceDifferential),
      /**
       * Is this plan upgrading from a lower tier?
       */
      isUpgradingTier,
      upgradeFromPlan,
      label: planModel.get('nameWithProduct'),
      breakdownLabel: planModel.get('nameWithProduct'),
      requiresAdditionalPlans: planModel.get('requiresAdditionalPlans'),
      showPricingMetrics: planModel.get('showPricingMetrics'),
      customTooltip: planModel.get('customTooltipContent'),
    };
  },

  priceRows: computed(
    'showSinglePrice',
    'showCurrentVsNewPrice',
    'customerService.prices',
    'quoteService.quotes',
    'quoteService.customerSubscriptionPrice',
    'intl.locale',
    function () {
      let newPlanPrice = this.shouldUseQuoteService
        ? this.quoteService.getQuoteById([this.plan.idAsNumber])
        : this.newPlanPrice;

      let newPlanDetails = {
        price: newPlanPrice,
        formattedPrice: this.formattedPriceDifferential,
        class: this.product.removing && 'change-plan-modal__removing-text',
        hideBreakdown: !newPlanPrice || this.product.removing,

        label: this.plan.get('nameWithProduct'),
        breakdownLabel: this.plan.get('nameWithProduct'),
        requiresAdditionalPlans: this.plan.get('requiresAdditionalPlans'),
        showPricingMetrics: this.plan.get('showPricingMetrics'),
        customTooltip: this.plan.get('customTooltipContent'),
      };

      if (isPresent(this.requiredAdditionalPlanIds)) {
        let additionalRequiredPlanDetails = this.requiredAdditionalPlanIds.map((id) =>
          this.getPlanDetails(id),
        );

        return [newPlanDetails, ...additionalRequiredPlanDetails];
      }

      if (this.showSinglePrice) {
        return [newPlanDetails];
      }

      if (this.showCurrentVsNewPrice) {
        let currentPlan = this.product.plans.find((plan) => plan.inCurrentSubscription);
        let currentPlanPrice = this.shouldUseQuoteService
          ? this.quoteService.customerSubscriptionPrice
          : currentPlan && this.customerService.getPriceFromPlanIds([currentPlan.idAsNumber]);

        return [
          {
            price: currentPlanPrice,
            label: this.intl.t('paywalls.upgrade-modal.subscription-upgrade.current-price'),
            breakdownLabel: this.intl.t(
              'paywalls.upgrade-modal.subscription-upgrade.current-price-breakdown',
              {
                price: currentPlanPrice?.breakdown?.firstObject.name,
              },
            ),
            formattedPrice: this.formattedOriginalPrice,
            hideBreakdown: !currentPlanPrice,
          },
          {
            price: newPlanPrice,
            label: this.intl.t('paywalls.upgrade-modal.subscription-upgrade.new-price'),
            breakdownLabel: this.intl.t(
              'paywalls.upgrade-modal.subscription-upgrade.new-price-breakdown',
              {
                price: newPlanPrice?.breakdown?.firstObject.name,
              },
            ),
            formattedPrice: this.formattedCurrentPrice,
            hideBreakdown: !newPlanPrice,
          },
          {
            label: this.intl.t('paywalls.upgrade-modal.subscription-upgrade.difference'),
            formattedPrice: this.formattedPriceDifferential,
            hideBreakdown: true,
          },
        ];
      }
      return [];
    },
  ),

  addOnLimit: computed('product.pricingMetric', function () {
    let LIMITS = {
      active_people: 20000,
      people_count: 10000,
      monthly_active_people: 10000,
      quarterly_active_people: 10000,
      inbox_seats: 100000,
    };
    return LIMITS[this.get('product.pricingMetric')];
  }),

  addOnIsSelfServe: computed('product', function () {
    return !this.metricNumber || !this.get('product.addon') || this.metricNumber < this.addOnLimit;
  }),

  onProPlan: computed('product.hasProPlan', 'product.hasOnePlan', function () {
    return this.get('product.hasProPlan') && !this.get('product.hasOnePlan');
  }),

  isPurchasingAddonWithoutSubscription: computed(
    'customer.hasActiveSubscription',
    'product.addon',
    function () {
      return !this.customer.hasActiveSubscription && this.product.addon;
    },
  ),

  isSelfServeAndInvalidPlanCombination: computed(
    'selfServe',
    'isValidPlanCombination',
    'app.hasActiveSubscription',
    function () {
      return this.selfServe && !this.isValidPlanCombination && this.app.hasActiveSubscription;
    },
  ),

  shouldSeeChatWithUsOption: computed(
    'selfServe',
    'isSelfServeAndInvalidPlanCombination',
    'requestingSmsWithGrowNgt',
    'onAnnualPricing5',
    function () {
      return (
        !this.selfServe ||
        this.isSelfServeAndInvalidPlanCombination ||
        this.requestingSmsWithGrowNgt ||
        this.onAnnualPricing5
      );
    },
  ),

  requestingSmsWithGrowNgt: computed(
    'plan.id',
    'selfServeTrialsService.activeNonGraduatingTrialPlanIds',
    function () {
      let activeNonGraduatingTrialPlanIds =
        this.selfServeTrialsService.activeNonGraduatingTrialPlanIds;
      return (
        activeNonGraduatingTrialPlanIds.includes(Number(CORE_GROW_ID)) &&
        this.plan.id === SMS_BASE_ID
      );
    },
  ),

  showNewSubscriptionLegalText: computed('selfServe', 'app.hasActiveSubscription', function () {
    return this.selfServe && !this.app.hasActiveSubscription;
  }),

  showTrialWithActiveSubscriptionText: computed(
    'selfServe',
    'app.hasActiveSubscription',
    'productIsTrialable',
    'isSelfServeAndInvalidPlanCombination',
    function () {
      return (
        this.selfServe &&
        this.app.hasActiveSubscription &&
        this.productIsTrialable &&
        !this.isSelfServeAndInvalidPlanCombination
      );
    },
  ),

  selfServe: and(
    'plan.selfServe',
    'addOnIsSelfServe',
    'customerCanEditCurrentSubscription',
    'isNotAddonWithoutSubscription',
  ),
  customerCanEditCurrentSubscription: readOnly('customerService.canEditCurrentSubscription'),
  isNotAddonWithoutSubscription: not('isPurchasingAddonWithoutSubscription'),
  planIsCustom: not('customer.canModifyOrRemoveProducts'),
  planIsAddon: readOnly('product.addon'),

  prices: readOnly('customerService.prices'),
  plans: readOnly('product.plans'),

  plan: null,

  formattedCurrentPrice: computed(
    'customerService.currentPrice',
    'quoteService.customerQuoteForPlan',
    'app.onPricing5',
    function () {
      if (this.shouldUseQuoteService) {
        return `$${numericFormatter(this.quoteService.customerQuoteForPlan.amount / 100, 2, true)}`;
      }

      let currPrice = this.get('customerService.currentPrice.amount');
      return `$${numericFormatter(currPrice / 100, 2, true)}`;
    },
  ),

  formattedOriginalPrice: computed(
    'customerService.originalPrice',
    'quoteService.customerSubscriptionPrice',
    'app.onPricing5',
    function () {
      if (this.shouldUseQuoteService) {
        return `$${numericFormatter(
          this.quoteService.customerSubscriptionPrice?.amount / 100,
          2,
          true,
        )}`;
      }
      let originalPrice = this.get('customerService.originalPrice.amount');
      return `$${numericFormatter(originalPrice / 100, 2, true)}`;
    },
  ),

  showSinglePrice: computed('selfServe', 'product.adding', 'product.removing', function () {
    return (
      (this.product.adding || this.product.removing) &&
      this.selfServe &&
      !this.downgradingAndLimitReached
    );
  }),

  showCurrentVsNewPrice: computed('selfServe', 'downgradingAndLimitReached', function () {
    return this.selfServe && !this.downgradingAndLimitReached;
  }),

  downgradingAndLimitReached: and('product.downgrading', 'teammateLimitReached'),

  descriptionText: computed(
    'planIsCustom',
    'customer.currentEarlyStageCustomer',
    'shouldSeeChatWithUsOption',
    'downgradingAndLimitReached',
    'isPurchasingAddonWithoutSubscription',
    'onAnnualPricing5',
    'intl.locale',
    function () {
      if (this.isPurchasingAddonWithoutSubscription) {
        return this.intl.t('paywalls.upgrade-modal.subscription-upgrade.addon-description');
      } else if (this.customer.currentEarlyStageCustomer) {
        return this.intl.t('paywalls.upgrade-modal.subscription-upgrade.early-stage-description');
      } else if (this.planIsCustom) {
        return this.intl.t('paywalls.upgrade-modal.subscription-upgrade.custom-description');
      } else if (this.downgradingAndLimitReached) {
        return this.intl.t('paywalls.upgrade-modal.subscription-upgrade.limit-description', {
          limit: this.plan.get('pricingStrategies').findBy('pricingMetric', CORE_SEAT_METRIC)
            ?.maximum,
        });
      } else if (this.onAnnualPricing5) {
        return this.intl.t(
          'paywalls.upgrade-modal.subscription-upgrade.pricing5.annual.description',
        );
      } else if (this.shouldSeeChatWithUsOption) {
        return this.intl.t('paywalls.upgrade-modal.subscription-upgrade.chat-with-us-description');
      } else {
        return '';
      }
    },
  ),

  calculatePriceDifferential(currentPriceModel, originalPriceModel) {
    let billingPeriodDurationInMonths = this.get(
      'customer.subscription.billingPeriodDurationInMonths',
    );

    return currentPriceModel?.diff(originalPriceModel, billingPeriodDurationInMonths, true) || 0;
  },

  shouldUseQuoteService: computed('plan', 'paywallService', function () {
    return this.paywallService.shouldUseQuoteService(this.plan.id);
  }),

  priceDifferential: computed(
    'customerService.currentPrice',
    'customerService.originalPrice',
    'app.onPricing5',
    'quoteService.customerQuoteForPlan',
    'quoteService.customerSubscriptionPrice',
    function () {
      if (this.shouldUseQuoteService) {
        return this.calculatePriceDifferential(
          this.quoteService.customerQuoteForPlan,
          this.quoteService.customerSubscriptionPrice,
        );
      } else {
        let currentPrice = this.get('customerService.currentPrice');
        let originalPrice = this.get('customerService.originalPrice');

        return this.calculatePriceDifferential(currentPrice, originalPrice);
      }
    },
  ),

  formattedPriceDifferential: computed('priceDifferential', function () {
    return this.formatPrice(this.priceDifferential);
  }),

  formatPrice(price) {
    let sign = '';
    if (price !== 0) {
      sign = price < 0 ? '-' : '+';
    }

    return `${sign}$${numericFormatter(Math.abs(price) / 100, 2, true)}`;
  },

  billedAsAmount: computed(
    'customerService.currentPrice',
    'customer.subscription.billingPeriodDurationInMonths',
    function () {
      let billingPeriod = this.get('customer.subscription.billingPeriodDurationInMonths');
      let amount =
        this.get('customerService.currentPrice')?.getBilledAsAmount(billingPeriod) -
        this.get('customerService.originalPrice')?.getBilledAsAmount(billingPeriod);
      return `$${numericFormatter(amount / 100, 2, true)}`;
    },
  ),

  productIsTrialable: and('product.inactive', 'product.trialable'),

  buttonText: computed(
    'productIsTrialable',
    'shouldSeeChatWithUsOption',
    'app.hasActiveSubscription',
    'product',
    'downgradingAndLimitReached',
    'isPurchasingAddonWithoutSubscription',
    'intl.locale',
    function () {
      if (this.isPurchasingAddonWithoutSubscription) {
        return this.intl.t('paywalls.upgrade-modal.subscription-upgrade.choose-plan');
        // if Multiplan and no additional required plans
        // Purchase add-on
      } else if (this.shouldSeeChatWithUsOption) {
        return this.intl.t('paywalls.upgrade-modal.subscription-upgrade.chat-with-us');
      } else if (this.productIsTrialable) {
        return this.intl.t('paywalls.upgrade-modal.subscription-upgrade.14-day-trial');
      } else if (!this.app.hasActiveSubscription) {
        return this.intl.t('paywalls.upgrade-modal.subscription-upgrade.start-subscription');
      } else if (
        isEmpty(this.requiredAdditionalPlanIds) &&
        this.plan.get('requiresAdditionalPlans')
      ) {
        return this.intl.t('paywalls.upgrade-modal.subscription-upgrade.purchase');
        // If multiplan and has additional required plans
        // Purchase all
      } else if (
        isPresent(this.requiredAdditionalPlanIds) &&
        this.plan.get('requiresAdditionalPlans')
      ) {
        return this.intl.t('paywalls.upgrade-modal.subscription-upgrade.purchase-all');
      } else if (this.downgradingAndLimitReached) {
        return this.intl.t('paywalls.upgrade-modal.subscription-upgrade.teammates');
      } else if (this.product.upgrading || this.product.adding) {
        return this.intl.t('paywalls.upgrade-modal.subscription-upgrade.upgrade');
      } else if (this.product.downgrading || this.product.removing) {
        return this.intl.t('paywalls.upgrade-modal.subscription-upgrade.downgrade');
      }
      return this.intl.t('paywalls.upgrade-modal.subscription-upgrade.chat-with-us');
    },
  ),

  // We need to prevent the button action only if we would fall through to the !selfServe path in the buttonAction CP below
  preventButtonAction: computed('isPurchasingAddonWithoutSubscription', 'selfServe', function () {
    return !this.isPurchasingAddonWithoutSubscription && !this.selfServe;
  }),

  // Any expansion of this buttonAction logic should take into account in what cases the button
  // should not fire the messenger but instead facilitate a custom bot via the preventButtonAction CP above
  buttonAction: computed(
    'app.hasActiveSubscription',
    'shouldSeeChatWithUsOption',
    'downgradingAndLimitReached',
    'isPurchasingAddonWithoutSubscription',
    function () {
      if (this.isPurchasingAddonWithoutSubscription) {
        return this.redirectToPurchase;
      } else if (this.shouldSeeChatWithUsOption) {
        return this.openMessenger;
      }
      if (this.downgradingAndLimitReached) {
        return this.redirectToTeammates;
      }
      return this.updateSubscription;
    },
  ),

  submitAction: reads('updateSubscription'),
  submitActionRunning: reads('sendNewOrUpdateSubscriptionTask.isRunning'),

  date14DaysFromToday: computed(function () {
    let options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(Date.now() + 12096e5).toLocaleDateString('en-US', options);
  }),

  redirectToBilling: action(function () {
    this.postTask();
    this.router.transitionTo('apps.app.billing.details', this.appService.app.id);
  }),

  redirectToTeammates: action(function () {
    this.postTask();
    this.router.transitionTo('apps.app.settings.workspace.teammates', this.appService.app.id);
  }),

  redirectToPurchase: action(function () {
    this.paywallService.redirectToPurchase(this.feature.key);
  }),

  openMessengerForEarlyStage: action(function () {
    this.postTaskAndTrackAnalyticsEvent();

    showNewMessageInIntercomWidget(
      this.intl.t('paywalls.upgrade-modal.subscription-upgrade.early-stage-messenger-text', {
        productName: this.productName,
      }),
    );
  }),

  openMessengerForCustom: action(function () {
    this.postTaskAndTrackAnalyticsEvent();

    showNewMessageInIntercomWidget(
      this.intl.t('paywalls.upgrade-modal.subscription-upgrade.custom-messenger-test'),
    );
  }),

  openMessengerForPlan: action(function () {
    this.postTaskAndTrackAnalyticsEvent();

    sendIntercomUpdate({ request_type: 'premium_tiers' });
    showNewMessageInIntercomWidget(this.get('plan.messengerText'));
  }),

  openMessengerForAddOn: action(function () {
    this.postTaskAndTrackAnalyticsEvent();

    let metric = this.metricNumber
      ? this.intl.t('paywalls.upgrade-modal.subscription-upgrade.for-metric', {
          metricNumber: this.metricNumber,
          pricingMetric: usageFormatter.startCase(this.get('product.pricingMetric')),
        })
      : '';
    showNewMessageInIntercomWidget(
      this.intl.t('paywalls.upgrade-modal.subscription-upgrade.addon-messenger-text', {
        productName: this.productName,
        metric,
      }),
    );
  }),

  openMessenger: action(function () {
    if (this.customer.currentEarlyStageCustomer) {
      this.openMessengerForEarlyStage();
    } else if (this.planIsCustom) {
      this.openMessengerForCustom();
    } else if (this.planIsAddon) {
      this.openMessengerForAddOn();
    } else {
      this.openMessengerForPlan();
    }
  }),

  postTaskAndTrackAnalyticsEvent: action(function () {
    this.postTask();

    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: this.eventObject(),
      planIds: this.activePlanIds,
      context: 'change_plan_modal',
    });
  }),

  eventObject() {
    if (this.customer.currentEarlyStageCustomer) {
      return 'chat_with_sales_early_stage_button';
    } else if (this.planIsCustom) {
      return 'chat_with_sales_custom_button';
    } else if (this.planIsAddon) {
      return 'chat_with_sales_addon_button';
    } else {
      return 'chat_with_sales_premium_button';
    }
  },
});
