/* import __COLOCATED_TEMPLATE__ from './paywall.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';

export interface Args {
  open?: boolean;
  planId: string;
  onClose?: () => void;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class ExperimentalPaywall extends Component<Signature> {
  @tracked open = this.args.open ?? false;

  @action
  onOpenChange(open: boolean) {
    this.open = open;
    if (!open) {
      this.args.onClose?.();
      document.body.style.pointerEvents = '';
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Paywalls::Experimental::Paywall': typeof ExperimentalPaywall;
    'paywalls/experimental/paywall': typeof ExperimentalPaywall;
  }
}
