/* import __COLOCATED_TEMPLATE__ from './fin-standalone-modal.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { showNewMessageInIntercomWidget } from 'embercom/lib/intercom-widget-helper';

import type RouterService from '@ember/routing/router-service';
export interface Args {
  onClose: () => void;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class FinStandaloneModal extends Component<Signature> {
  @service declare customerService: any;
  @service declare appService: any;
  @service declare router: RouterService;
  @service declare intl: any;
  @service declare purchaseAnalyticsService: any;

  get isSelfServe(): boolean {
    return this.customerService.customer.everInCardlessTrial;
  }

  get bodyText(): string {
    if (this.isSelfServe) {
      return this.intl.t('paywalls.fin-standalone.body.self-serve');
    }
    return this.intl.t('paywalls.fin-standalone.body.sales-led');
  }

  get buttonLabel(): string {
    if (this.isSelfServe) {
      return this.intl.t('paywalls.fin-standalone.button-label.self-serve');
    }
    return this.intl.t('paywalls.fin-standalone.button-label.sales-led');
  }

  @action
  buttonAction() {
    if (this.isSelfServe) {
      this.sendAnalyticsEvent('subscribe_now');
      this.router.transitionTo('apps.app.teams-checkout', this.appService.app.id);
    } else {
      this.sendAnalyticsEvent('talk_to_sales');
      showNewMessageInIntercomWidget();
    }
    this.args.onClose();
  }

  @action
  sendAnalyticsEvent(object: string) {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object,
      place: 'paywalls',
      context: 'fin_standalone',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Paywalls::FinStandalone::FinStandaloneModal': typeof FinStandaloneModal;
    'paywalls/fin-standalone/fin-standalone-modal': typeof FinStandaloneModal;
  }
}
