/* import __COLOCATED_TEMPLATE__ from './collaborator-seat-limit-reached-hover-card.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { showNewMessageInIntercomWidget } from 'embercom/lib/intercom-widget-helper';

export default class CurrentAndFuturePriceComponent extends Component {
  @service purchaseAnalyticsService;

  @action talkToSales(section) {
    let textForMessenger = 'Hey, I’m interested in adding additional Collaborator seats';
    let event = {
      action: 'clicked',
      object: 'chat_with_us',
      place: 'seats_editor_card',
      context: 'adding / editing teammates',
      section,
    };

    this.purchaseAnalyticsService.trackEvent(event);

    showNewMessageInIntercomWidget(textForMessenger);
  }
}
