/* import __COLOCATED_TEMPLATE__ from './select-group-block.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency-decorators';

export default class SelectGroupBlock extends Component {
  @dropTask
  *openUpgradeModal() {
    yield this.args.hideDropdownOverlay();
    yield this.args.item.openUpgradeModal();
  }
}
