/* import __COLOCATED_TEMPLATE__ from './already-trialled.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  planId: string;
}

const AlreadyTrialledComponent = templateOnlyComponent<Signature>();
export default AlreadyTrialledComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Paywalls::UpgradeModal::Sidebar::AlreadyTrialled': typeof AlreadyTrialledComponent;
  }
}
