/* import __COLOCATED_TEMPLATE__ from './salesforce-contracted.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { FIN_AI_COPILOT_BASE_ID } from 'embercom/lib/billing';

import type Plan from 'embercom/models/plan';
import type QuoteService from 'embercom/services/quote-service';
import type { Metric } from 'embercom/models/data/pricing/metric-types';

interface Signature {
  isDowngrade: boolean;
  selectedPlanName: string;
  showUpgradePathPlans: boolean;
  featureLessHeaders: boolean;
  closeUpgradeModal: () => void;
  plan: Plan;
  featureName: string;
}

export default class SalesforceContracted extends Component<Signature> {
  @service purchaseAnalyticsService: any;
  @service declare appService: any;
  @service declare quoteService: QuoteService;
  @service declare intl: any;

  get seatPrice() {
    let seatPrice = this.quoteService
      .getQuoteById([this.args.plan.idAsNumber])
      ?.fullSeatPrice(this.args.plan.idAsNumber, this.args.plan.product.pricingMetric as Metric);
    return seatPrice ? this.quoteService.formatPrice(seatPrice) : undefined;
  }

  get isCopilotSideBar() {
    return this.args.plan.id === FIN_AI_COPILOT_BASE_ID;
  }

  get loading() {
    return this.quoteService.loading;
  }

  get descriptionWithSeatPrice(): string | undefined {
    let seatPrice = this.quoteService
      .getQuoteById([this.args.plan.idAsNumber])
      ?.fullSeatPrice(this.args.plan.idAsNumber, this.args.plan.product.pricingMetric as Metric);
    return seatPrice
      ? this.intl.t('paywalls.upgrade-modal.sidebar.salesforce-contracted.copilot.description', {
          seatPrice: this.quoteService.formatPrice(seatPrice),
          htmlSafe: true,
        })
      : undefined;
  }

  @action
  trackAnalyticsEventAndClose() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'chat_with_us',
      place: 'paywall_copilot_sidebar',
      context: 'upgrade_annual_plan',
      planId: this.args.plan.idAsNumber,
      featureName: this.args.featureName,
    });

    this.args.closeUpgradeModal();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Paywalls::UpgradeModal::Sidebar::SalesforceContracted': typeof SalesforceContracted;
  }
}
