/* import __COLOCATED_TEMPLATE__ from './with-pricing-metrics.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import BillingConstants from 'embercom/lib/billing';
import PricingMetric from 'embercom/lib/purchase/pricing-metric';
import type IntlService from 'embercom/services/intl';

interface CustomTooltipContent {
  text: string;
  subtext?: string;
  link: {
    name: string;
    path: string;
  };
}

interface Args {
  hasValueBasedPricing: boolean;
  priceRow: {
    /**
     * If custom tooltip does not exist, don't show tooltip icon.
     */
    customTooltip: CustomTooltipContent;
    [key: string]: any;
  };
  product: any;
}

interface PricingChargeMetricItem {
  amount: string;
  name: string;
}

/**
 * Displays additional metrics under a multiplan:
 * app/lib/billing:MODAL_ADD_PLAN_INFO `allowedPricingMetricsCharge`
 */
export default class PriceRowWithPricingMetricsComponent extends Component<Args> {
  @service declare intl: IntlService;

  @tracked hasValueBasedPricing = false;

  get hasCustomTooltip(): boolean {
    return isPresent(this.args.priceRow?.customTooltip?.text);
  }

  get hasLink(): boolean {
    return (
      isPresent(this.args.priceRow.customTooltip.link?.name) &&
      isPresent(this.args.priceRow.customTooltip.link?.path)
    );
  }

  /**
   * Assumes the active plan is the one we want a breakdown of
   *
   * Note: If we wanted this to be more isolated, we'd need to make sure
   * the plan is also passed down into this component.
   **/
  get pricingChargeMetrics(): PricingChargeMetricItem[] {
    let priceModel = this.args.priceRow.price;
    let charges = priceModel.breakdown.firstObject.charges;
    let allowedPricingMetricKeys =
      // @ts-ignore has implicit any -> fix: type the constants
      BillingConstants.MODAL_ADD_PLAN_INFO[priceModel.planIds.firstObject]
        .allowedPricingMetricsCharge;

    return charges
      .filter((charge: any) => allowedPricingMetricKeys.includes(charge.pricing_metric))
      .map((metric: any) => {
        let pricingMetricClass = new PricingMetric(metric, this.intl);
        return {
          name: pricingMetricClass.pluralize(),
          amount: pricingMetricClass.formattedMinimumPaidTier(),
        };
      });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Paywalls::UpgradeModal::PriceRow::WithPricingMetrics': typeof PriceRowWithPricingMetricsComponent;
    'paywalls/upgrade-modal/price-row/with-pricing-metrics': typeof PriceRowWithPricingMetricsComponent;
  }
}
