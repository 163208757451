/* import __COLOCATED_TEMPLATE__ from './footer.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  planId?: string;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

const PaywallsUpgradeModalSidebarSharedFooterComponent = templateOnlyComponent<Signature>();
export default PaywallsUpgradeModalSidebarSharedFooterComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Paywalls::UpgradeModal::Sidebar::Shared::Footer': typeof PaywallsUpgradeModalSidebarSharedFooterComponent;
  }
}
