/* import __COLOCATED_TEMPLATE__ from './button.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

interface Signature {
  Element: HTMLButtonElement;
  Args: {
    planId: string;
  };
  Blocks: {
    default: [];
  };
}

export default class ExperimentalPaywallButton extends Component<Signature> {
  @service declare paywallService: $TSFixMe;

  @action
  openExperimentalPaywall() {
    this.paywallService.openExperimentalPaywall(this.args.planId);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Paywalls::Experimental::Button': typeof ExperimentalPaywallButton;
    'paywalls/experimental/button': typeof ExperimentalPaywallButton;
  }
}
