/* import __COLOCATED_TEMPLATE__ from './start-trial.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import templateOnlyComponent from '@ember/component/template-only';
import { type Task } from 'ember-concurrency';

interface Signature {
  planId: string;
  startContractTrialTask: Task<any, any>;
}

const StartTrialComponent = templateOnlyComponent<Signature>();
export default StartTrialComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Paywalls::UpgradeModal::Sidebar::StartTrial': typeof StartTrialComponent;
  }
}
