/* import __COLOCATED_TEMPLATE__ from './pinnable-segment-list.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface Args {
  section: any;
  route: string;
  routeModels: string;
  isOnRoute: boolean;
  exitSearch: () => void;
  segments: any;
  icon: 'company' | 'multiple-people';
  titleLabel: string;
  activeSegment: any;
}

interface Signature {
  Args: Args;
  Element: never;
}

export default class PinnableSegmentList extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare router: RouterService;

  @tracked query = '';

  get segments() {
    return this.args.segments;
  }

  get admin() {
    return this.appService.app.currentAdmin;
  }

  get hiddenSegments() {
    let segments = this.hidableSegments.filter((seg: any) => !this.visibleSegments.includes(seg));
    if (this.query && this.query.length > 0) {
      return segments.filter((seg: any) =>
        seg.name.toLowerCase().includes(this.query.toLowerCase()),
      );
    }
    return segments;
  }

  get hidableSegments() {
    return this.segments.filter((segment: any) => segment.is_editable);
  }

  get nonEditableSegments() {
    return this.segments.filter((segment: any) => !segment.is_editable);
  }

  get visibleSegments() {
    let visibleSegmentIds = this.admin.visible_segment_ids;
    if (!visibleSegmentIds) {
      return [];
    }
    let segments = this.hidableSegments.filter((segment: any) =>
      visibleSegmentIds.includes(segment.get('id')),
    );
    if (this.query && this.query.length > 0) {
      return segments.filter((seg: any) =>
        seg.name.toLowerCase().includes(this.query.toLowerCase()),
      );
    }
    return segments;
  }

  get footerTransitionRoute() {
    if (this.args.icon === 'company') {
      return 'apps.app.settings.personal.visible-to-you.company-segments';
    }
    return 'apps.app.settings.personal.visible-to-you.user-segments';
  }

  @action
  showSegment(segment: any) {
    this.admin.addSegmentIdsToAdminsVisibleSegmentIds([segment.get('id')]);
    this.admin.save();
  }

  @action
  hideSegment(segment: any) {
    this.admin.removeSegmentIdsFromAdminsVisibleSegmentIds([segment.get('id')]);
    this.admin.save();
  }

  @action
  onPinSelected(action: 'show' | 'hide', segment: any) {
    if (action === 'show') {
      this.showSegment(segment);
    } else {
      this.hideSegment(segment);
    }
  }

  @action
  onItemClicked(segment: any) {
    this.router.transitionTo(this.args.route, segment.get('id'));
  }

  @action
  onTitleLinkClicked() {
    this.args.exitSearch();
    this.router.transitionTo(this.args.route, this.args.routeModels);
  }

  @action onQueryChanged(query: string) {
    this.query = query;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    PinnableSegmentList: typeof PinnableSegmentList;
  }
}
