/* import __COLOCATED_TEMPLATE__ from './request-free-trial.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type CatalogApi from 'embercom/services/catalog-api';

export interface Args {
  closeUpgradeModal: () => void;
  productName: string;
  requestFreeTrial: () => void;
  planId: string;
}

export default class RequestFreeTrial extends Component<Args> {
  @service declare catalogApi: CatalogApi;

  @action
  closeModals() {
    this.catalogApi.closeSuccessModal();
    this.args.closeUpgradeModal();
  }

  @action
  closeModalsAndOpenMessenger() {
    this.catalogApi.openMessenger();
    this.args.closeUpgradeModal();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Paywalls::UpgradeModal::Sidebar::RequestFreeTrial': typeof RequestFreeTrial;
    'paywalls/upgrade-modal/sidebar/request-free-trial': typeof RequestFreeTrial;
  }
}
